import React from "react"
import Ipad from "../components/Ipad.js"
import styled from "styled-components"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { graphql } from "gatsby"

const Destination = ({ data }) => {
  const {
    activities,
    sideCopy,
    sideImage,
    stayCopy,
    stayImage,
    stayTitle,
    subtitle,
    title,
    place,
  } = data.allPagesJson.nodes[0]
  return (
    <Ipad data={{ bgColor: "var(--blu)" }}>
      <div
        style={{
          position: "fixed",
          bottom: "0",
          left: "50%",
          transform: "translateX(-50%)",
          width: "100%",
          height: "40vh",
          background: "#F2F2F2",
          zIndex: "1",
        }}
      />
      <Link
        to="/"
        style={{
          position: "fixed",
          top: "2vw",
          right: "2vw",
          zIndex: "6",
        }}
      >
        <StaticImage
          height={50}
          layout="fixed"
          src="../images/x.png"
          alt="cross logo"
        />
      </Link>
      <Container>
        <Topbar>
          <h1>Choose a destination</h1>
        </Topbar>
        <div className="title">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
        <div className="main">
          <section className="sidebar">
            <GatsbyImage
              image={getImage(sideImage)}
              alt={title}
              className="img"
            />
          </section>
          <section className="stuff">
            <div className="info">
              <div className="activities">
                <h2>What will you do?</h2>
                {activities.map((activity, i) => {
                  return (
                    <div className="activity" key={i}>
                      <h3>{activity.title}</h3>
                      <p>{activity.copy}</p>
                    </div>
                  )
                })}
              </div>
              <div className="stay">
                <h2>Where to stay?</h2>
                <h3>{stayTitle}</h3>
                <p
                  style={{
                    marginBottom: "2rem",
                  }}
                >
                  {stayCopy}
                </p>
                <GatsbyImage
                  image={getImage(stayImage)}
                  alt={title}
                  className="img"
                />
              </div>
            </div>
            <div className="footer">
              <p>{sideCopy}</p>
              <Link
                to="/ticket"
                state={{ destination: toTitleCase(title), place: place }}
              >
                <button>Book</button>
              </Link>
            </div>
          </section>
        </div>
      </Container>
    </Ipad>
  )
}

const toTitleCase = (str) => {
  if (str !== null) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  }
}

const Container = styled.div`
  width: 95%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  .title {
    font-family: GEB;
    text-align: center;
    margin-bottom: 2rem;
    h1 {
      margin: 0;
      margin-bottom: 0;
      font-size: 2.8rem;
      color: white;
    }
    p {
      color: var(--azul);
      margin-top: 5px;
      font-size: 1.2rem;
    }
  }

  .main {
    padding: 1.5rem;
    display: flex;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
    background: white;
    border-radius: 20px;
    color: var(--blu);
    .stuff {
      display: flex;
      flex-direction: column;
      width: 720px;
      h2,
      h3 {
        font-family: GEB;
        margin-bottom: 0.5rem;
      }
      p {
        font-family: GL;
      }
      h2 {
        font-size: 1.7rem;
        margin-bottom: 1rem;
      }
      h3,
      p {
        font-size: 1rem;
        line-height: 120%;
      }
      .info {
        display: flex;
        border-bottom: 1px solid var(--azul);
        height: 370px;
        .activities {
          width: 450px;
          border-right: 1px solid var(--azul);
          .activity {
            width: 340px;
            margin-bottom: 1.8rem;
          }
        }
        .stay {
          width: 230px;
          padding: 0 40px;
          .img {
            width: 160px;
            margin: 0 auto;
            display: block;
          }
        }
      }
      .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        p {
          width: 350px;
          font-size: 1.3rem;
          line-height: 125%;
        }
        button {
          border-radius: 75px;
          font-family: GEB;
          font-size: 1.4rem;
          color: white;
          background: var(--blu);
          padding: 25px 80px;
          border: 0px;
        }
      }
    }
  }

  .sidebar {
    width: 300px;
    .img {
      border-radius: 10px;
      width: 100%;
      height: 500px;
    }
  }
`

const Topbar = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 2vw;
  margin-bottom: 3rem;
  font-family: GEB;
  color: var(--azul);
  font-size: 0.8rem;
`

export const query = graphql`
  query GetSinglePost($slug: String) {
    allPagesJson(filter: { slug: { eq: $slug } }) {
      nodes {
        activities {
          copy
          title
        }
        slug
        subtitle
        title
        place
        sideImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        sideCopy
        stayTitle
        stayCopy
        stayImage {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default Destination
